<template>
  <!-- Table Container Card -->
  <b-card no-body>
    <b-table
      id="refListTable"
      ref="refListTable"
      no-provider-paging
      no-provider-filtering
      :filter-included-fields="columnToBeFilter"
      :fields="tableColumns"
      :items="itemLists"
      :per-page="perPage"
      :current-page="currentPage"
      responsive
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :busy="isBusy"
      empty-text="No matching records found"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
      @row-clicked="rowClicked"
    >

      <template #table-busy>
        <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
          <div class="text mb-1">
            <b-spinner class="align-middle" />
          </div>
          <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
        </div>
      </template>

      <!-- Column: code -->
      <!-- <template #cell(code)="data">
        <b-link
          v-if="data.item.is_account_parent || Number(data.item.account_level) === 1"
          :to="{ name: 'apps-master-data-coa-detail', params: { id: data.item.id }}"
          class="d-block, text-nowrap, font-weight-bolder"
          :style="setPadding(data.item)"
        >
          {{ data.item.account_number }} - {{ data.item.account_name }}
        </b-link>

        <b-link
          v-else
          :to="{ name: 'apps-master-data-coa-detail', params: { id: data.item.id }}"
          class="d-block, text-nowrap"
          :style="setPadding(data.item)"
        >
          {{ data.item.account_number }} - {{ data.item.account_name }}
        </b-link>
      </template> -->

      <!-- Column: Account Subype -->
      <!-- <template #cell(accountSubtypes)="data">
        <span v-if="data.item.is_account_parent === false" class="font-weight-bold d-block text-nowrap">
          {{ data.value.subtype_name }}
        </span>
      </template> -->

      <!-- Column: Normal Balance -->
      <template #cell(Total)="{ item }">
        <span class="font-weight-bold text-nowrap">
           {{ formatCurrency(item.Total) }}
        </span>
      </template>

      <template #cell(actions)="{ item }">
        <div class="d-flex justify-content-between">
          <b-button
            class="mr-2"
            variant="outline-danger"
            @click="handleModuleReject(item)"
          >
            <!-- <feather-icon
              icon="XIcon"
            /> -->
            {{ $t('globalActions.reject') }}
          </b-button>
          
          <b-button
            class="mr-2"
            variant="outline-primary"
            @click="handleModuleRevision(item)"
          >
            <!-- <feather-icon
              icon="XIcon"
            /> -->
            {{ $t('globalActions.revision') }}
          </b-button>

          <b-button
            variant="primary"
            @click="handleModuleApprove(item)"
          >
            <!-- <feather-icon
              icon="CheckIcon"
            /> -->
            {{ $t('globalActions.approve') }}
          </b-button>                  
        </div>
      </template>
    </b-table>

    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalList"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
            aria-controls="refInvoiceListTable"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

  </b-card>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  // BFormInput,
  BButton,
  BTable,
  // BLink,
  BPagination,
  BSpinner
} from 'bootstrap-vue'

import { onMounted } from '@vue/composition-api'
// import useList from './useList'
import { formatCurrency } from '@/utils/formatter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import useListTable from '@/comp-functions/useListTable'
import useHttp from '@/comp-functions/useHttp'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    // BFormInput,
    BButton,
    BTable,
    // BLink,
    BPagination,
    BSpinner
  },
  setup () {
    const { $put } = useHttp()
    // Table Handlers
    const tableColumns = [
      { key: 'module', sortable: true },
      { key: 'transaction_code', label: 'Transaction Code', sortable: true },
      { key: 'user_name', label: 'Approved User' },
      { key: 'description' },
      { key: 'Total', label: 'Amount'},
      { key: 'actions'}
    ]
    
    // Table Handlers
    const columnToBeFilter = [
      'account_number',
      'account_name',
      'account_subtype_code'
    ]

    const setPadding = (row) => {
      return `padding-left: ${(row.account_level - 1) * 30}px;`
    }

    const rowClicked = item => {
      const transactions = [
        { code: 'PR', module: 'purchase-request' },
        { code: 'PO', module: 'purchase-order' }
      ]
      const module = transactions.find(transaction => transaction.code === item.transaction_type).module
      router.push({
        name: `apps-transactions-${module}-detail`,
        params: { id: item.transaction_id, fromApproval: true, approvalId: item.id }
      })
    }

    const {
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      statusFilter,
      fetchLists,
      changePerPage
    } = useListTable({ url: 'transaction/module-approval' })

    // console.log(itemLists)

    onMounted(() => {
      changePerPage(30)
    })

    return {
      formatCurrency,
      $put,
      rowClicked,
      tableColumns,
      columnToBeFilter,
      setPadding,
      isBusy,
      itemLists,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      fetchLists,
      statusFilter,
      changePerPage
    }
  },
  methods: {
    async handleModuleApprove (item) {
      this.$swal({
        title: 'Approve this Purchase Request ?',
        text: 'You can\'t undo this actions',
        icon: 'warning',
        input: 'textarea',
        inputLabel: 'Reason',
        inputPlaceholder: 'Your reason',
        inputAttributes: {
          'aria-label': 'Your reason'
        },
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      })
        .then(({value: reason}) => {
          if (reason) {
            this.$put({
              url: `transaction/module-approval/${item.id}`,
              data: {
                is_approved: true,
                reason
              }
            })
              .then(() => {
                this.fetchLists()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Success',
                    icon: 'BellIcon',
                    variant: 'success',
                    text: 'Successfully approved data'
                  }
                })
              })
            // router.push({ name: 'apps-transactions-module-approval-list' })
          }
        })
    },
    async handleModuleReject (item) {
      this.$swal({
        title: 'Reject this Purchase Request ?',
        text: 'You can\'t undo this actions',
        icon: 'warning',
        input: 'textarea',
        inputLabel: 'Reason',
        inputPlaceholder: 'Your reason',
        inputAttributes: {
          'aria-label': 'Your reason'
        },
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      })
        .then(({value: reason}) => {
          if (reason) {
            this.$put({
              url: `transaction/module-approval/${item.id}`,
              data: {
                is_approved: false,
                reason
              }
            })
              .then(() => {
                this.fetchLists()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Success',
                    icon: 'BellIcon',
                    variant: 'success',
                    text: 'Successfully reject data'
                  }
                })
              })
            // router.push({ name: 'apps-transactions-module-approval-list' })
          }
        })
    },
    async handleModuleRevision (item) {
      this.$swal({
        title: 'Revision this Purchase Request ?',
        text: 'This will give user permission to revision the data',
        icon: 'warning',
        input: 'textarea',
        inputLabel: 'What\'s need to be correct',
        inputPlaceholder: 'Your correction',
        inputAttributes: {
          'aria-label': 'Your reason'
        },
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-flat-warning'
        }
      })
        .then(({value: reason}) => {
          if (reason) {
            this.$put({
              url: `transaction/module-approval/${item.id}`,
              data: {
                is_approved: false,
                reason
              }
            })
              .then(() => {
                this.fetchLists()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Success',
                    icon: 'BellIcon',
                    variant: 'success',
                    text: 'Successfully reject data'
                  }
                })
              })
            // router.push({ name: 'apps-transactions-module-approval-list' })
          }
        })
    }
  }
}
</script>

<style lang="scss">
#refListTable {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}
</style>
